import React from 'react'
import { FormProvider } from 'react-hook-form'

import type { FieldsAPI } from './fields.api'
import type { FieldValues } from 'react-hook-form'

import { ControllerContext, FieldControllerApi } from './fields.hooks'
import FieldError, { FieldErrorProps } from './types/field.error.component'

export interface FieldsControllerProps<Item extends FieldValues> extends Omit<FieldControllerApi, 'onKeyDown'>, FieldErrorProps {
  api: FieldsAPI<Item>

  onEnter?: (event: React.KeyboardEvent<HTMLElement>) => void
}

export default function FieldsController<Item extends FieldValues>({
  api,

  namePrefix,
  skeleton = false,
  loading,
  disabled,

  error,
  errorMessages,

  onEnter,

  children
}: React.PropsWithChildren<FieldsControllerProps<Item>>) {
  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key.toLowerCase() === 'enter' && onEnter) {
      event.preventDefault()

      onEnter(event)
    }
  }, [onEnter])

  return (
    <ControllerContext.Provider
      value={{
        namePrefix,
        loading,
        disabled,
        onKeyDown: handleKeyDown
      }}>
      <FormProvider {...api.form}>
        {children}

        <FieldError
          error={error}
          errorMessages={errorMessages} />
      </FormProvider>
    </ControllerContext.Provider>
  )
}
