import React, { FormEventHandler } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import type { InputBaseComponentProps } from '@mui/material/InputBase'

import FieldText, { FieldTextProps } from './field.text.component'

export interface FieldNumberProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> extends FieldTextProps<TFieldValues, TName> {
  decimals?: number
}

export default function FieldNumber<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  decimals = 0,
  ...field
}: FieldNumberProps<TFieldValues, TName>) {
  const handleChange = React.useCallback((onChange: FormEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined) => ({ value }: {
    value: string
  }) => {
    onChange?.({
      target: {
        // @ts-expect-error weird thing
        name: field.name,
        value: decimals
          ? parseInt(value)
          : parseFloat(value)
      }
    })
  }, [decimals, field.name])

  const inputComponent = React.useMemo(() => React.forwardRef((
    { onChange, defaultValue, value, ...props }: InputBaseComponentProps,
    ref
  ) => {
    return (
      <NumericFormat
        {...props}
        allowedDecimalSeparators={[',', '.']}
        allowNegative={false}
        decimalScale={decimals}
        decimalSeparator={','}
        getInputRef={ref}
        onValueChange={handleChange(onChange)}
        thousandSeparator={false}
        value={value}
        valueIsNumericString
      />
    )
  }), [decimals, handleChange])

  return (
    <FieldText
      {...field}
      inputComponent={inputComponent}
      inputMode={'numeric'}
    />
  )
}
