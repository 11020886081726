import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import Button, { buttonClasses } from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import type { FieldRadioProps } from './field.radio.component'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase from './field.base.component'

export type FieldRadioButtonProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<FieldRadioProps<TFieldValues, TName>, 'size'>

const StyledButton = styled(Button)(({ theme }) => ({
  // Remove the darker hover
  [`&.${buttonClasses.contained}:hover`]: {
    backgroundColor: theme.palette.secondary.main
  }
}))

export default function FieldRadioButton<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  hint,
  options
}: FieldRadioButtonProps<TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)
  const validate = useFieldValidate(validations)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  return (
    <FieldBase
      error={fieldState.error}
      hidden={hidden}
      hint={hint}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      required={required}>
      <Stack
        direction={'row'}
        gap={1}>
        {options.map((option) => (
          <StyledButton
            key={`${option.value}`}
            color={'secondary'}
            disabled={disabled || controller.disabled}
            onClick={() => field.onChange(option.value)}
            size={'small'}
            variant={option.value === field.value ? 'contained' : 'outlined'}>
            {option.label}
          </StyledButton>
        ))}
      </Stack>
    </FieldBase>
  )
}
