export enum Action {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export enum ScopeAction {
  None = 'none',
  Manage = 'manage',
  Write = 'write', // Action.Create & Action.Update & Action.Read
  Read = 'read'
}
