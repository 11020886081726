import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import LogoWhiteSvg from '@oi/react/assets/logo/white.svg'
import { format } from 'date-fns/format'

export interface FooterProps {
  variant?: 'default' | 'in-app'
}

const StyledFooter = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  backgroundColor: theme.palette.secondary.dark,
  width: '100%',
  height: theme.spacing(10),
  padding: theme.spacing(2),

  '& > img': {
    height: '100%'
  }
}))

const StyledFooterStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center'
  }
}))

const StyledInAppFooter = styled(StyledFooterStack)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}))

const StyledFooterLink = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize
}))

export default function Footer({ variant }: FooterProps) {
  if (variant === 'in-app') {
    return (
      <StyledInAppFooter
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}>
        <Typography
          order={{ xs: 1, md: 0 }}
          variant={'subtitle2'}>
          © {format(new Date(), 'yyyy')} - Online Incasso
        </Typography>
        <StyledFooterStack
          direction={'row'}>
          <StyledFooterLink href={'#terms'}>
            <FormattedMessage
              defaultMessage={'General delivery and privacy conditions'}
              id={'footer.terms'} />
          </StyledFooterLink>
          <StyledFooterLink href={'#privacy'}>
            <FormattedMessage
              defaultMessage={'Privacy statement'}
              id={'footer.privacy'} />
          </StyledFooterLink>
        </StyledFooterStack>
      </StyledInAppFooter>
    )
  }

  return (
    <StyledFooter>
      <img
        alt={'Online Incasso'}
        src={LogoWhiteSvg} />
    </StyledFooter>
  )
}

