import { useQuery } from '@tanstack/react-query'

import { bailiffsApi } from './api.constants'
import { unwrapResponse } from './api.internal'

export function useActiveBailiffs() {
  return useQuery({
    queryKey: ['bailiffs'],
    queryFn: () => unwrapResponse(bailiffsApi.bailiffsQueryMany({}))
  })
}
