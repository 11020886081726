import React, { FormEventHandler } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import BigNumber from 'bignumber.js'

import type { InputBaseComponentProps } from '@mui/material/InputBase'

import FieldText, { FieldTextProps } from './field.text.component'

export interface FieldMoneyProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> extends FieldTextProps<TFieldValues, TName> {
  allowNegative?: boolean
}

export default function FieldMoney<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ allowNegative = false, ...field }: FieldMoneyProps<TFieldValues, TName>) {
  const handleChange = React.useCallback((onChange: FormEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined) => ({ value }: {
    value: string | number
  }) => {
    let newValue: BigNumber | number | null = new BigNumber(value)

    if (newValue.isNaN()) {
      newValue = null

    } else {
      newValue = newValue
        .toNumber()
    }

    onChange?.({
      target: {
        // @ts-expect-error weird thing
        name: field.name,
        value: newValue
      }
    })
  }, [field.name])

  const inputComponent = React.useMemo(() => React.forwardRef((
    { onChange, defaultValue: _defaultValue, value: stringValue, ...props }: InputBaseComponentProps,
    ref
  ) => {
    let value = parseFloat(stringValue) || stringValue

    if (!value || isNaN(stringValue)) {
      value = ''
    }

    return (
      <NumericFormat
        {...props}
        allowedDecimalSeparators={[',', '.']}
        allowNegative={allowNegative}
        decimalScale={2}
        decimalSeparator={','}
        getInputRef={ref}
        onValueChange={handleChange(onChange)}
        thousandSeparator={false}
        value={value}
        valueIsNumericString
      />
    )
  }), [handleChange, allowNegative])

  return (
    <FieldText
      {...field}
      inputComponent={inputComponent}
      startAdornment={<>&euro;</>}
    />
  )
}
