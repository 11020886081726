import type { Locale } from 'date-fns'

import { Language, LoadMessages,LoadMessagesResponse } from './language.interfaces'

const STORAGE_KEY = '___oi_interface_language'
const messagesCache = {} as {
  [key: string]: LoadMessagesResponse
}

const supportedLanguages = Object.values(Language).map((key) => key.toLowerCase()) as string[]
const fallbackLanguage = Language.English.toLowerCase()

export function addMessagesToCache(language: Language, messages: LoadMessagesResponse['messages'], dateMessages: object) {
  messagesCache[language] = {
    messages,
    dateMessages: dateMessages
  }
}

export function getMessagesFromCache(language: Language): LoadMessagesResponse {
  return messagesCache[language]
}

export function getInitialLocale() {
  const language = localStorage.getItem(STORAGE_KEY)

  if (!language && navigator.language) {
    const browserLanguage = navigator.language

    // Check if the browser language is one we support
    if (browserLanguage && supportedLanguages.includes(browserLanguage.toLowerCase())) {
      return browserLanguage
    }
  }

  return language || fallbackLanguage
}

export function storeNewLocale(newLocale: string) {
  localStorage.setItem(STORAGE_KEY, newLocale)
}

export function getMessages(loadMessages: LoadMessages, language: Language) {
  const cache = getMessagesFromCache(language)

  if (cache) {
    return cache
  }

  // Suspense is based on ErrorBoundary
  // throwing a promise will cause <SomeLoadingComponent /> to render until the promise resolves
  throw loadMessages(language)
}

export async function getDateMessages(language: string): Promise<Locale> {
  switch (language) {
    // case 'nl':
    //   return (await import('date-fns/locale/nl')).nl
    //   break

    default:
      return (await import('date-fns/locale/en-GB')).enGB
  }
}
