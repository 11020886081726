export enum Language {
  English = 'en-GB',
  Dutch = 'nl-NL'
}

export interface LoadMessagesResponse {
  messages: {
    [key: string]: string
  }
  dateMessages?: string | object
}

export type LoadMessages = (language: Language) => Promise<void>

export interface Locale {
  code: Language
  language: string
}
