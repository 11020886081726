import React from 'react'
import { useLoaderData } from 'react-router-dom'

import { useOnMountEffect } from './use-on-mount-effect'

interface AwaitLoaderDataResponse<Response> {
  loading: boolean
  data: Response | null
  error: Error | null
}

export function useAwaitLoaderData<Response>(): AwaitLoaderDataResponse<Response> {
  const loaderData = useLoaderData() as { data: Promise<Response> }
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<Response | null>(null)
  const [error, setError] = React.useState<Error | null>(null)

  useOnMountEffect(() => {
    setLoading(true)
    loaderData.data
      .then(setData)
      .catch(setError)
      .finally(() => {
        setLoading(false)
      })
  })

  return {
    loading,
    error,
    data
  }
}
