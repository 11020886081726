import type { ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidateNumber } from './validate.hooks'

export interface ValidateMaxOptions {
  maximum: number
  maximumError?: string
}

export function validateMax<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: ValidateMaxOptions): ValidationResult {
  return {
    valid: shouldValidateNumber(value)
      ? value <= options.maximum
      : true,
    message: intl.formatMessage({
      id: 'fields.error.max',
      defaultMessage: 'The maximum is {maximum}!'
    }, {
      maximum: options.maximumError || options.maximum
    })
  }
}
