import { creditCaseAPi } from './api.constants'
import { getCreditCaseQueryKey } from './api.credit-case'
import { useWrapMethod } from './api.internal'
import { refetchQueries } from './api.methods'
import {
  CreditCasesApiPaymentPlanCreateOneRequest,
  CreditCasesApiPaymentPlanDeleteOneRequest,
  CreditCasesApiPaymentPlanSuggestOneRequest,
  SuggestedPlannedPayment
} from './open-api'

export function useDeleteCreditCasePaymentPlanMutation() {
  return useWrapMethod<
    CreditCasesApiPaymentPlanDeleteOneRequest,
    void
  >(
    (params) => creditCaseAPi.paymentPlanDeleteOne(params),
    async (params) => {
      // Updates the credit case and the timeline
      await refetchQueries(getCreditCaseQueryKey(params.creditCaseId))
    }
  )
}

export function useSuggestCreditCasePaymentPlanMutation() {
  return useWrapMethod<
    CreditCasesApiPaymentPlanSuggestOneRequest,
    Array<SuggestedPlannedPayment>
  >(
    (params) => creditCaseAPi.paymentPlanSuggestOne(params)
  )
}

export function useCreateCreditCasePaymentPlanMutation() {
  return useWrapMethod<
    CreditCasesApiPaymentPlanCreateOneRequest,
    void
  >(
    (params) => creditCaseAPi.paymentPlanCreateOne(params),
    async (params) => {
      // Updates the credit case and the timeline
      await refetchQueries(getCreditCaseQueryKey(params.creditCaseId))
    }
  )
}
