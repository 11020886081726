import React from 'react'
import { useFieldArray } from 'react-hook-form'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { AddLineIcon, DeleteBinFillIcon } from '@oi/react/components/icons'

import { ControllerContext, useFieldName, useFieldsController } from '../fields.hooks'
import FieldGroup from './field.group.component'

export interface FieldCollectionProps {
  name: string
  minimum?: number
  addLabel: string | React.ReactNode
  required?: boolean
  disableCreate?: boolean
  appendObject?: object
}

const AddButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5)
}))

export default function FieldCollection({
  name,
  children,

  minimum = 1,
  addLabel,
  required,
  disableCreate = false,
  appendObject = {}
}: React.PropsWithChildren<FieldCollectionProps>) {
  const controller = useFieldsController()
  const fieldName = useFieldName(name)

  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    rules: { required }
  })

  return (
    <>
      {fields.map((field, fieldIndex) => (
        <Stack key={field.id}>
          <ControllerContext.Provider
            value={{
              ...controller,
              namePrefix: `${fieldName}.${fieldIndex}`
            }}>
            <FieldGroup
              gridTemplateColumns={'1fr 32px'}
              name={`${fieldName}.${fieldIndex}`}>
              {children}

              <Stack
                height={'100%'}
                position={'relative'}>
                <IconButton
                  color={'primary'}
                  disabled={fields.length <= minimum || controller.disabled}
                  onClick={(() => remove(fieldIndex))}
                  sx={{
                    position: 'absolute',
                    bottom: 8
                  }}>
                  <DeleteBinFillIcon fontSize={'inherit'} />
                </IconButton>
              </Stack>
            </FieldGroup>
          </ControllerContext.Provider>
        </Stack>
      ))}

      {!disableCreate && (
        <AddButtonContainer>
          <Button
            color={'secondary'}
            disabled={controller.disabled}
            onClick={(() => append(appendObject))}
            startIcon={<AddLineIcon />}
            variant={'contained'}>
            {addLabel}
          </Button>
        </AddButtonContainer>
      )}
    </>
  )
}
