import type { FieldPath, FieldPathValue, FieldValues } from 'react-hook-form'

export function shouldValidate<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(value: FieldPathValue<TFieldValues, TFieldName>): boolean {
  return Boolean(typeof value === 'string' ? value?.trim()?.length : value)
}

export function shouldValidateNumber<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(value: FieldPathValue<TFieldValues, TFieldName>): boolean {
  return Boolean(typeof value === 'number')
}
