import {
  addMessagesToCache,
  getDateMessages,
  Language,
  LoadMessagesResponse
} from '@oi/react/modules/language'

export function fetchTranslations(languageCode: string): Promise<LoadMessagesResponse['messages']> {
  return import('../translations/en-GB.json') as never
  // return fetch(`https://cdn.simplelocalize.io/7d6ef1ff99e54d76a241e79ecd8e2d7e/_latest/${languageCode}`)
  //   .then((response) => response.json())
  //   .catch(() => import('../translations/en.json'))
}

export async function loadMessages(language: Language) {
  const dateMessages = await getDateMessages(language)
  const messages = await fetchTranslations(language)

  addMessagesToCache(language, messages, dateMessages)
}
