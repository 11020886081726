import React from 'react'
import { FormattedMessage } from 'react-intl'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu, { menuClasses } from '@mui/material/Menu'
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem'
import { paperClasses } from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { alpha, styled } from '@mui/material/styles'
import { ArrowDownSLineIcon, ArrowUpSLineIcon, LogoutCircleRLineIcon } from '@oi/react/components/icons'
import { HEADER_TUTORIAL_MENU, useSpotlightAPI } from '@oi/react/components/spotlight'
import TutorialTooltip from '@oi/react/components/tutorial-tooltip'
import { useActiveUser } from '@oi/react/modules/api'

import { useMenuItems } from './header-items.hooks'
import TutorialMenu from './tutorial-tooltip-menu.component'

export interface HeaderMenuProps {
  logout: () => void
}

const StyledAccountLink = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.secondary.main,

  '&:hover': {
    color: theme.palette.secondary.main
  },

  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    padding: 0
  },

  [`& .${menuClasses.list}`]: {
    padding: 0
  },

  [`& .${menuItemClasses.root}`]: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5, 2),

    '& svg': {
      fill: theme.palette.text.primary
    },

    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),

      '& svg': {
        fill: theme.palette.secondary.main
      }
    }
  }
}))

export default function HeaderMenu({ logout }:HeaderMenuProps) {
  const activeUser = useActiveUser()

  const { isInSpotlight: showMenuTutorial } = useSpotlightAPI(HEADER_TUTORIAL_MENU)

  const menuAnchorRef = React.useRef(null)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const menuItems = useMenuItems(showMenuTutorial)

  React.useEffect(() => {
    setIsMenuOpen(showMenuTutorial)
  }, [showMenuTutorial])

  const handleMenuOpen = React.useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const handleMenuClose = React.useCallback(() => {
    if (!showMenuTutorial) {
      setIsMenuOpen(false)
    }

  }, [showMenuTutorial])

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={2}>
      <StyledAccountLink
        ref={menuAnchorRef}
        aria-controls={isMenuOpen ? 'account-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup={'true'}
        onClick={handleMenuOpen}
      >
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={1}>
          {activeUser.name}
          {isMenuOpen ? <ArrowUpSLineIcon fontSize={'medium'} /> : <ArrowDownSLineIcon fontSize={'medium'} />}
        </Stack>
      </StyledAccountLink>

      <StyledMenu
        anchorEl={menuAnchorRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        id={'account-menu'}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
        open={isMenuOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        transitionDuration={showMenuTutorial ? 0 : 'auto'}>
        <TutorialTooltip
          component={<TutorialMenu />}
          placement={'left-end'}
          spotlightKey={HEADER_TUTORIAL_MENU}
          hideItemFrame>
          <div>
            {menuItems.map(({ key, icon, to, selected, label }) => (
              <MenuItem
                key={key}
                component={Link}
                href={to}
                selected={selected}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                {label}
              </MenuItem>
            ))}
          </div>

          <Divider variant={'fullWidth'} />

          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutCircleRLineIcon fontSize={'small'} />
            </ListItemIcon>

            <FormattedMessage
              defaultMessage={'Log out'}
              id={'menu.user.logout'} />
          </MenuItem>
        </TutorialTooltip>
      </StyledMenu>
    </Stack>
  )
}
