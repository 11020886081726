import { isAxiosError } from 'axios'

export function isResourceNotFoundError(error?: Error | null) {
  if (error && isAxiosError(error)) {
    return error.status === 404
      || (error.status === 400 && error.response?.data?.fields?.id === 'id must be a number string')
  }

  return false
}

export function isUnauthorizedError(error?: Error | null) {
  if (error && isAxiosError(error)) {
    return error.status === 401
  }

  return false
}
