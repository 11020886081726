import React from 'react'
import Stack from '@mui/material/Stack'

import type { FieldValues } from 'react-hook-form'

import Controller, { FieldsControllerProps } from './fields.controller'

export interface FieldsProps<Item extends FieldValues> extends FieldsControllerProps<Item> {
  className?: string
}

export default function Fields<Item extends FieldValues>({
  children,
  className,
  ...rest
}: React.PropsWithChildren<FieldsProps<Item>>) {
  return (
    <Stack
      className={className}
      component={'form'}
      gap={1.5}
      style={{ position: 'relative', width: '100%' }}>
      <Controller {...rest}>
        {children}
      </Controller>
    </Stack>
  )
}
