import type { ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidateNumber } from './validate.hooks'

export interface ValidateMinOptions {
  minimum: number
  minimumError?: string
}

export function validateMin<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: ValidateMinOptions): ValidationResult {
  return {
    valid: shouldValidateNumber(value)
      ? value >= options.minimum
      : true,
    message: intl.formatMessage({
      id: 'fields.error.min',
      defaultMessage: 'The minimum is {minimum}!'
    }, {
      minimum: options.minimumError || options.minimum
    })
  }
}
