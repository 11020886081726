import React from 'react'
import { isAxiosError } from 'axios'

import type { QueryKey } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'

import { apiGlobalErrorMessage } from './api.messages'
import { ApiError, ListOptions, queryClient } from './api.methods'

export const DEFAULT_LIST_OPTIONS: ListOptions = { limit: 25, offset: 0 }

interface ApiHookResponse<Output> {
  error?: ApiError
  data: Output | null
}

// https://github.com/TanStack/query/discussions/2770#discussioncomment-3584906

/**
 * All API methods return AxiosResponse, we want to directly return the data
 */
export function unwrapResponse<Response extends AxiosResponse>(method: Promise<Response>): Promise<Response['data']> {
  return method.then(({ data }) => data)
}

export type CacheUpdater<Input, Output, Cache = Output> = (existing: Cache, data: Output, params: Input) => Cache

function updateReactQueryCache<Input, Output, Cache = Output>(id: QueryKey, record: Output, params: Input, cacheUpdater: CacheUpdater<Input, Output, Cache>): void {
  queryClient.setQueryData(id, cacheUpdater(queryClient.getQueryData(id) || {} as Cache, record, params))
}

export function useWrapMethod<Input, Output, Cache = Output>(
  method: (params: Input) => Promise<AxiosResponse<Output>>,
  getQueryCacheKey?: (params: Input, data: Output) => QueryKey | Promise<void>,
  cacheUpdater: CacheUpdater<Input, Output, Cache> = (existing, data, params: Input) => ({
    ...existing,
    ...data
  })
): [
  (params: Input) => Promise<ApiHookResponse<Output>>,
  {
    error?: ApiError
    loading: boolean
  }
] {
  const [loading, setLoading] = React.useState(false)
  const [apiError, setApiError] = React.useState<ApiError>()

  return React.useMemo(() => [
    async (params: Input): Promise<ApiHookResponse<Output>> => {
      setLoading(true)
      setApiError(undefined)

      try {
        const { data } = await method(params)

        if (getQueryCacheKey) {
          const cacheKey = await getQueryCacheKey(params, data)

          if (cacheKey) {
            updateReactQueryCache(cacheKey, data, params, cacheUpdater)
          }
        }

        return {
          data
        }
      } catch (err) {
        let error = {
          message: apiGlobalErrorMessage.defaultMessage,
          code: apiGlobalErrorMessage.id
        }

        if (isAxiosError(err) && err.response?.data) {
          error = err.response.data as never as ApiError
        }

        setApiError(error)

        return {
          data: null,
          error
        }
      } finally {
        setLoading(false)
      }
    },
    {
      error: apiError,
      loading
    }
  ], [apiError, cacheUpdater, getQueryCacheKey, loading, method])
}
