import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Hidden from '@mui/material/Hidden'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography, { typographyClasses } from '@mui/material/Typography'
import Fields, { Field, fieldClasses, useFieldsAPI } from '@oi/react/components/fields'
import { LogoColorIcon, RegisterIcon } from '@oi/react/components/icons'

import type { RegisterProps } from '../register.component'

import { useRedirectUserWhenLoggedIn } from '../../auth.hook'
import { useRegisterSubtitle } from './register-form.hook'

interface RegisterDto {
  email: string
  password: string
  passwordRepeat: string
  newsletter: boolean
  privacyConditions: boolean
  privacyStatement: boolean
}

interface RegisterFormProps extends RegisterProps {
  toggleIllustrationVisibility: () => void
}

const StyledStackWrapper = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    alignItems: 'start',
    justifyContent: 'start'
  },

  [`& .${typographyClasses.root}`]: {
    width: '100%'
  }
}))

const StyledStackForm = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',

    [`& .${fieldClasses.groupContainer}`]: {
      gridTemplateColumns: 'initial'
    }
  }
}))

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

const StyledOpenTutorialButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  flex: 1
}))

export default function RegisterForm({ type, toggleIllustrationVisibility }: RegisterFormProps) {
  const fieldsAPI = useFieldsAPI<RegisterDto>()
  const intl = useIntl()

  useRedirectUserWhenLoggedIn()

  const handleRegisterClick = React.useCallback(async () => {
    const { isValid, values } = await fieldsAPI.getValues()

    if (isValid && values) {
      // const { error } = await login({
      //   client_id: type,
      //   // rememberComputer: false,
      //   ...values
      // })

      // if (!error) {
      //   handleNavigate()
      // }
    }
  }, [fieldsAPI])

  const subtitle = useRegisterSubtitle(type)

  return (
    <>
      <StyledStackWrapper
        alignItems={'center'}
        display={'flex'}
        flexGrow={1}
        justifyContent={'center'}>
        <Hidden mdUp>
          <Stack mb={6}>
            <LogoColorIcon />
          </Stack>
        </Hidden>
        <Typography
          textAlign={'center'}
          variant={'h1'}
          gutterBottom>
          <FormattedMessage
            defaultMessage={'Create an account'}
            id={'routes.register.title'} />
        </Typography>

        <Typography
          mb={6}
          textAlign={'center'}
          variant={'body1'}>
          {subtitle}
        </Typography>

        <StyledStackForm>

          <Fields
            api={fieldsAPI}
            onEnter={handleRegisterClick}>
            <Field.Text
              name={'email'}
              label={intl.formatMessage({
                id: 'routes.register.email',
                defaultMessage: 'Email'
              })}
              validations={{
                email: true
              }}
              required
            />

            <Field.Group name={'register.password'}>
              <Field.Text
                name={'password'}
                type={'password'}
                label={intl.formatMessage({
                  id: 'routes.register.password',
                  defaultMessage: 'Password'
                })}
                validations={{
                  score: true
                }}
                required
              />
              <Field.Text
                name={'passwordRepeat'}
                type={'password'}
                label={intl.formatMessage({
                  id: 'routes.register.password-repeat',
                  defaultMessage: 'Repeat password'
                })}
                validations={{
                  sameAs: {
                    field: 'password',
                    fieldLabel: intl.formatMessage({
                      id: 'routes.register.password',
                      defaultMessage: 'Password'
                    })
                  }
                }}
                required
              />
            </Field.Group>

            <Stack>
              <Field.Checkbox
                name={'newsletter'}
                label={intl.formatMessage({
                  id: 'routes.register.accept.newsletter',
                  defaultMessage: 'Yes, I would like to receive updates about Online Incasso'
                })}
              />

              <Field.Checkbox
                name={'privacyConditions'}
                label={intl.formatMessage({
                  id: 'routes.register.accept.privacy-conditions',
                  defaultMessage: 'Yes, I agree to the general delivery and privacy conditions'
                })}
                required
              />

              <Field.Checkbox
                name={'privacyStatement'}
                label={intl.formatMessage({
                  id: 'routes.register.accept.privacy-statement',
                  defaultMessage: 'Yes, I agree with the privacy statement'
                })}
                required
              />
            </Stack>
          </Fields>

          <Stack
            alignItems={'end'}
            display={'flex'}
            gap={3}
            mt={5}>
            <StyledLoadingButton
              onClick={handleRegisterClick}
              variant={'contained'}>
              <FormattedMessage
                defaultMessage={'Register'}
                id={'btn.register'} />
            </StyledLoadingButton>

            <Hidden mdUp>
              <Stack
                direction={'row'}
                gap={2}
                width={'100%'}>
                <RegisterIcon
                  height={58}
                  width={58} />

                <StyledOpenTutorialButton
                  color={'secondary'}
                  onClick={toggleIllustrationVisibility}
                  variant={'contained'}>
                  <FormattedMessage
                    defaultMessage={'How does it work?'}
                    id={'btn.how-does-it-work'} />
                </StyledOpenTutorialButton>
              </Stack>
            </Hidden>
          </Stack>
        </StyledStackForm>
      </StyledStackWrapper>

      <Divider />

      <Stack
        mb={5}
        mt={3}
        textAlign={'center'}>
        <Typography variant={'body1'}>
          <FormattedMessage
            defaultMessage={'Do you have an account?'}
            id={'routes.register.login'} />
          &nbsp;
          <Link
            href={'/login'}
            underline={'always'}>
            <FormattedMessage
              defaultMessage={'Login'}
              id={'btn.login'} />
          </Link>
        </Typography>
      </Stack>
    </>
  )
}
