import type {
  CreditCasesApiCaseActionUpdateFailedReasonRequest
} from './open-api'

import { creditCaseAPi } from './api.constants'
import { getCreditCaseTimelineQueryKey } from './api.credit-case'
import { useWrapMethod } from './api.internal'
import { refetchQueries } from './api.methods'

export function useUpdateCreditCaseActionFailedReasonMutation() {
  return useWrapMethod<
    CreditCasesApiCaseActionUpdateFailedReasonRequest,
    void
  >(
    (params) => creditCaseAPi.caseActionUpdateFailedReason(params),
    async (params) => {
      await refetchQueries(getCreditCaseTimelineQueryKey(params.creditCaseId))
    }
  )
}
