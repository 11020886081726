import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel'
import Radio, { radioClasses, RadioProps } from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import { styled } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'

import type { BaseField } from '../fields.interface'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase from './field.base.component'

export interface RadioFieldOption {
  label: string
  value: string | boolean
}

export interface FieldRadioProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<RadioProps, 'required' | 'disabled' | 'size'>, BaseField {
  name: TName
  label?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>

  options: RadioFieldOption[]
}

const StyledRadioGroup = styled(RadioGroup)<RadioGroupProps>(({ theme }) => ({
  gap: theme.spacing(),

  [`& .${typographyClasses.root}`]: {
    fontSize: 14
  },

  [`& .${radioClasses.root}`]: {
    padding: 6
  },

  [`& .${formControlLabelClasses.label}`]: {
    marginLeft: '0 !important'
  }
}))

export default function FieldRadio<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  hint,
  size,
  options
}: FieldRadioProps<TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)
  const validate = useFieldValidate(validations)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  return (
    <FieldBase
      error={fieldState.error}
      hidden={hidden}
      hint={hint}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      required={required}>
      <StyledRadioGroup
        onChange={field.onChange}
        value={field.value}
        row>
        {options.map((option) => (
          <FormControlLabel
            key={option.label}
            disabled={disabled || controller.disabled}
            label={option.label}
            value={option.value}
            control={(
              <Radio
                color={'primary'}
                size={size || 'small'} />
            )}
          />
        ))}
      </StyledRadioGroup>
    </FieldBase>
  )
}
