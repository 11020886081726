import React from 'react'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid2'
import Hidden from '@mui/material/Hidden'
import { styled } from '@mui/material/styles'

export interface IllustrationContainerProps {
  showIllustration?: boolean
  illustration: React.ReactNode
}

const StyledDiv = styled('div')({
  minHeight: 'calc(100dvh)',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
})

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 700,
  display: 'flex',
  flexDirection: 'column',

  padding: theme.spacing(8, 8, 0),

  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(6, 6, 0)
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 4, 0)
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 3, 0)
  }
}))

export const StyledIllustrationContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',

  '& img': {
    maxWidth: '100%'
  }
}))

export default function IllustrationContainer({
  children,
  illustration,
  showIllustration
}: React.PropsWithChildren<IllustrationContainerProps>) {
  return (
    <Fade
      in>
      <Grid container>
        <Hidden mdDown={!showIllustration}>
          <Grid
            size={{
              md: 6,
              xl: 5,
              xs: 12
            }}>
            <StyledIllustrationContainer>
              {illustration}
            </StyledIllustrationContainer>
          </Grid>
        </Hidden>
        <Hidden mdDown={showIllustration}>
          <Grid
            size={{
              md: 6,
              xl: 7,
              xs: 12
            }}>
            <StyledDiv>
              <StyledContainer>
                {children}
              </StyledContainer>
            </StyledDiv>
          </Grid>
        </Hidden>
      </Grid>
    </Fade>
  )
}
